import { Col, Row } from 'react-bootstrap'
import Gallery from 'react-photo-gallery'
import Carousel, { Modal, ModalGateway } from 'react-images'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { isMobile } from 'react-device-detect'
import * as styles from './ImageGallery.module.scss'
import Link from '../Elements/Link/Link'

interface Image {
	alt: string
	key: string
	src: string
	height: number
	width: number
}

export default () => {
	const header = useRef(null)
	const [currentImage, setCurrentImage] = useState(0)
	const [viewerIsOpen, setViewerIsOpen] = useState(false)
	const [moreText, setMoreText] = useState('Daugiau')
	const [getPhotos, setPhotos] = useState<Image[]>([])
	const [galleryImages, setGalleryImages] = useState<Image[]>([])
	const data = useStaticQuery(graphql`
		{
			allFile(
				filter: {
					extension: { regex: "/(jpg)|(png)|(jpeg)/" }
					absolutePath: { regex: "/(gallery)/" }
				}
				sort: { fields: modifiedTime, order: DESC }
			) {
				edges {
					node {
						base
						childImageSharp {
							original {
								src
								height
								width
							}
							fixed {
								originalName
							}
						}
					}
				}
			}
		}
	`)

	useEffect(() => {
		if (data.allFile.edges) {
			console.log(data.allFile.edges)
			const generatedPhotos = data.allFile.edges.map(
				(
					image: {
						node: { childImageSharp: { original: { src: string; height: number; width: number } } }
					},
					index: number
				) => {
					const { src, width, height } = image.node.childImageSharp.original
					return {
						src,
						width: width > height ? 3 : 2,
						height: 2,
						alt: `remontas ${index}`,
						key: `image${index}`
					}
				}
			)
			setPhotos(generatedPhotos)
			setGalleryImages(generatedPhotos.slice(0, 6))
		}
	}, [data.allFile.edges])

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const openLightbox = useCallback((_event, { index }) => {
		setCurrentImage(index)
		setViewerIsOpen(true)
	}, [])

	const closeLightbox = () => {
		setCurrentImage(0)
		setViewerIsOpen(false)
	}

	const handleScroll = (ref: any) =>
		window.scrollTo({
			top: ref.current.offsetTop - 86,
			left: 0
		})

	const handleMore = () => {
		if (galleryImages.length > 6) {
			handleScroll(header)
			setMoreText('Daugiau')
			setGalleryImages(getPhotos.slice(0, 6))
		} else {
			setMoreText('Sumažinti')
			setGalleryImages(getPhotos)
		}
	}

	if (getPhotos && galleryImages) {
		return (
			<Row className='pt-5 pb-5' ref={header}>
				<Col>
					<div className={styles.galleryContainer}>
						<Gallery
							photos={galleryImages}
							onClick={openLightbox}
							direction='column'
							columns={isMobile ? 4 : 6}
						/>
						<ModalGateway>
							{viewerIsOpen ? (
								<Modal onClose={closeLightbox}>
									<Carousel
										currentIndex={currentImage}
										views={getPhotos.map((x) => ({
											...x,
											source: x.src
										}))}
									/>
								</Modal>
							) : null}
						</ModalGateway>
						<div className='text-center pt-3'>
							<Link onClick={handleMore} text={moreText} />
						</div>
					</div>
				</Col>
			</Row>
		)
	}

	return null
}
