import React, { useContext, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import classNames from 'classnames'
import * as styles from './Service.module.scss'
import SectionHeading from '../components/SectionHeading/SectionHeading'
import Card from '../components/Card/Card'
import ServiceHeader from '../images/service_header.jpg'
import ImageGallery from '../components/ImageGallery/ImageGallery'
import { CHANGE_HEADER_IMAGE } from '../store/actionTypes'
import { Context } from '../store/Provider'
import Header from '../components/Header/Header'
import Seo from '../components/Seo/Seo'

interface Props {
	location: Location
}

export default ({ location }: Props) => {
	const { dispatch } = useContext(Context)

	useEffect(() => {
		dispatch({
			type: CHANGE_HEADER_IMAGE,
			payload: {
				image: ServiceHeader,
				title: 'Servisas',
				viewHeight: 50,
				parallax: false,
				isButtonDownEnabled: false
			}
		})
	}, [dispatch])

	return (
		<div>
			<Seo title='Servisas' pathName={location.pathname} />
			<Header
				image={ServiceHeader}
				title='Servisas'
				viewHeight={50}
				parallax={false}
				isButtonDownEnabled={false}
			/>
			<div className={styles.containerGrey}>
				<Container className={classNames(styles.containerServices, 'pt-5')}>
					<SectionHeading title='Taisymas ir priežiūra' />
					<Row className='pt-5'>
						<Col>
							<Card className='mb-5' title='Sunkvežimių'>
								<div className={styles.listContainer}>
									<ul className={styles.list}>
										<li>Lingių keitimas ir remontas</li>
										<li>Variklių kapitalinis remontas</li>
										<li>Greičių dežės perrinkimas ir kapitalinis remontas</li>
										<li>Elektros instaliacijos taisymas ir priežiūra</li>
									</ul>
									<ul className={styles.list}>
										<li>Purkštukų patikrinimas ir reguliavimas</li>
										<li>Šildymo pečiukų patikrinimas ir remontas</li>
										<li>Detalus važiuoklės patikrinimas</li>
										<li>Tepalų ir filrų keitimas</li>
									</ul>
									<ul className={styles.list}>
										<li>Dažymo darbai ir paruošimas</li>
										<li>Diagnostikos patikra ir darbai</li>
										<li>Paruošimas tehnninei apžiūrai</li>
										<li>Stabžių sistemos taisymas</li>
										{/* <li> */}
										{/*	Konsultacija kaip atitinkamai prižiūrėti transporto priemonę ir sutaupyti kurą */}
										{/* </li> */}
										{/* <li> */}
										{/*	Nelaimingo įvykio metu mes pasirūpinsime bendradarbiavimu su visomis */}
										{/*	susijusiomis šalimis */}
										{/* </li> */}
									</ul>
								</div>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col>
							<Card className='mb-5' title='Priekabų ir puspriekabių'>
								<div className={styles.listContainer}>
									<ul className={styles.list}>
										<li>Visų tipų puspriekebių pakabos perrinkimas ir remontas</li>
										<li>Thermo King šaldytuvų remontas ir pilnas aptarnavimas</li>
										<li>Oro Sistemos Knorr ir Wabco remontas</li>
									</ul>
									<ul className={styles.list}>
										<li>Grindų keitimas, tvarkymas</li>
										<li>Kaladėlių keitimas</li>
										<li>NRG taisymas</li>
									</ul>
									<ul className={styles.list}>
										<li>ABS taisymas</li>
										<li>Stabžių sistemos taisymas</li>
									</ul>
								</div>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col>
							<Card className='mb-5' title='Cisternų'>
								<div className={styles.listContainer}>
									<ul className={styles.list}>
										<li>Cisternos lyginimas ir kapitalinis remontas</li>
										<li>Suvirinimas ir restauravimas</li>
									</ul>
									<ul className={styles.list}>
										<li>Izotermos pašiltinimas</li>
										<li>Kiti nestandartiniai darbai</li>
									</ul>
								</div>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col>
							<Card className='mb-5' title='Lifto darbai'>
								<div className={styles.listContainer}>
									<ul className={styles.list}>
										<li>Galime uždėti liftą</li>
										<li>Nuimti liftą ir uždėti duris</li>
										<li>Keltuvo remontas</li>
									</ul>
									<ul className={styles.list}>
										<li>Cilindrų remontas</li>
										<li>Solenoidų taisymas</li>
									</ul>
									<ul className={styles.list}>
										<li>Vožtuvų ir elektros darbai</li>
										<li>Hidraulikos remontas</li>
									</ul>
								</div>
							</Card>
						</Col>
					</Row>
				</Container>
				<Container>
					<SectionHeading title='Mūsų darbai' />
					<ImageGallery />
				</Container>
			</div>
		</div>
	)
}
